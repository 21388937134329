<template>
    <div>
        <h2>Engineering Feed Back Details</h2>
        <br />
        <b-table
            v-if="details.enggFeedbackSubmitted"
            striped
            hover
            :items="details.enggFeedback"
            :fields="fields"
        ></b-table>

        <div v-if="userRole == 'Engineering' || userRole == 'Administrator'">
            <div v-if="!details.enggFeedbackSubmitted">
                <b-button variant="primary" @click="submitComment"
                    >Save</b-button
                >
                &nbsp;&nbsp;
                <b-button variant="primary" @click="addNewComment"
                    >Add More Feedbacks</b-button
                >
                <br />

                <b-form inline>
                    <div
                        class="form-row mt-3"
                        v-for="(comment, index) in comments"
                        :key="index"
                    >
                        <b-form-input
                            id="inline-form-input-name"
                            class="mb-2 mr-sm-2 mb-sm-0"
                            placeholder="Description"
                            v-model="comment.description"
                        ></b-form-input>

                        <b-form-select
                            class="mb-2 mr-sm-2 mb-sm-0"
                            v-model="comment.status"
                            :options="options"
                        ></b-form-select>

                        <b-form-input
                            id="inline-form-input-name"
                            class="mb-2 mr-sm-2 mb-sm-0"
                            placeholder="comments"
                            v-model="comment.comment"
                        ></b-form-input>

                        &nbsp;&nbsp;
                        <b-button variant="danger" @click="remove(index)"
                            >X</b-button
                        >
                    </div>
                </b-form>
            </div>
        </div>
    </div>
</template>
<script>
import unitMixin from "../../../mixins/unitMixin";
export default {
    mixins: [unitMixin],
    props: {
        details: Object,
        type: String,
        url: String,
    },
    data() {
        return {
            selected: null,
            options: [
                { value: null, text: "Please select an option" },
                { value: "No Objection", text: "No Objection" },
                { value: "Rejected", text: "Rejected" },
            ],
            workAssigned: [],
            userRole: null,
            fields: [
                { key: "id" },
                { key: "description" },
                { key: "status" },
                { key: "comment" },
            ],
            comments: [{ description: "", status: "", comment: "" }],
        };
    },
    mounted() {
        this.userRole = localStorage.cs_user_role;
    },
    methods: {
        submitComment() {
            let descriptions = this.comments.map((item) => {
                return item.description;
            });
            let statuses = this.comments.map((item) => {
                return item.status;
            });
            let comments = this.comments.map((item) => {
                return item.comment;
            });
            let payload = {};
            if (this.type === "hi" || this.type === "cm") {
                payload.request_id = this.details.id;
                payload.type = this.type;
                payload.description = descriptions;
                payload.status = statuses;
                payload.comment = comments;
            } else {
                payload.request_id = this.details.id;
                payload.request_type = this.type;
                payload.description = descriptions;
                payload.status = statuses;
                payload.comment = comments;
            }

            console.log(payload);
            this.submitEngineeringComments(this.url, payload).then(() => {
                this.$emit("refreshContent");
            });
        },
        addNewComment() {
            this.comments.push({ description: "", status: "", comment: "" });
        },
        remove(index) {
            this.comments.splice(index, 1);
        },
    },
};
</script>
