<template>
    <div>
        <h2>Engineering Approvals</h2>

        <br />
        <div class="row">
            <div class="col-lg-3">
                <h5>Civil Approval</h5>
                <b-button
                    v-if="
                        !details.civil_approval &&
                        userRole == 'Engineering Executive'
                    "
                    variant="primary"
                    @click="approve(details.id, 'engg_civil')"
                >
                    Approve
                </b-button>
                <p
                    v-if="
                        !details.civil_approval &&
                        userRole != 'Engineering Executive'
                    "
                >
                    Status : Pending Approval
                </p>
                <p v-if="details.civil_approval">
                    Approved By : {{ details.civil_approval_by }}
                </p>
                <p>Date : {{ details.civil_approval }}</p>
            </div>

            <div class="col-lg-3">
                <h5>MEP Approval</h5>
                <b-button
                    v-if="
                        !details.mep_approval &&
                        userRole == 'Engineering Executive'
                    "
                    variant="primary"
                    @click="approve(details.id, 'engg_mep')"
                >
                    Approve
                </b-button>
                <p
                    v-if="
                        !details.mep_approval &&
                        userRole != 'Engineering Executive'
                    "
                >
                    Status : Pending Approval
                </p>
                <p v-if="details.mep_approval">
                    Approved By : {{ details.mep_approval_by }}
                </p>
                <p>Date : {{ details.mep_approval }}</p>
            </div>

            <div class="col-lg-3">
                <h5>Architecture Approval</h5>
                <b-button
                    v-if="
                        !details.architecture_approval &&
                        userRole == 'Engineering Executive'
                    "
                    variant="primary"
                    @click="approve(details.id, 'engg_architecture')"
                >
                    Approve
                </b-button>
                <p
                    v-if="
                        !details.architecture_approval &&
                        userRole != 'Engineering Executive'
                    "
                >
                    Status : Pending Approval
                </p>
                <p v-if="details.architecture_approval">
                    Approved By : {{ details.architecture_approval_by }}
                </p>
                <p>Date : {{ details.architecture_approval }}</p>
            </div>

            <div class="col-lg-3">
                <h5>Engineering Approval</h5>
                <b-button
                    v-if="!details.engg_approval && userRole == 'Engineering'"
                    variant="primary"
                    @click="approve(details.id, 'engg')"
                >
                    Approve
                </b-button>
                <p v-if="!details.engg_approval && userRole != 'Engineering'">
                    Status : Pending Approval
                </p>
                <p v-if="details.engg_approval">Approved</p>
                <p>Date : {{ details.engg_approval }}</p>
            </div>
        </div>
    </div>
</template>
<script>
import unitMixin from "../../../mixins/unitMixin";
export default {
    mixins: [unitMixin],
    props: {
        details: Object,
        type: String,
    },
    data() {
        return {
            userRole: null,
        };
    },
    methods: {
        approve(id, dept) {
            this.homeAndCommercialApproval(id, dept, this.type).then(() => {
                this.$emit("refreshContent");
            });
        },
    },
    mounted() {
        if (localStorage.cs_user_token) {
            this.userRole = localStorage.cs_user_role;
        }
    },
};
</script>
