<template>
    <div>
        <h2>Facility Management Approvals</h2>
        <br />
        <div class="row">
            <div class="col-lg-4">
                <h5>FM Executive Approval</h5>
                <b-button
                    variant="primary"
                    v-if="
                        !details.fm_executive_approval &&
                        userRole == 'FM Executive'
                    "
                    @click="approve(details.id, 'fm_executive')"
                >
                    Approve
                </b-button>
                <p
                    v-if="
                        !details.fm_executive_approval &&
                        userRole != 'FM Executive'
                    "
                >
                    Status : Pending approval
                </p>
                <p v-if="details.fm_executive_approval">
                    Approved By {{ details.fm_executive_approval_by }}
                </p>
                <p>Date : {{ details.fm_executive_approval }}</p>
            </div>
            <div class="col-lg-4">
                <h5>FM Approval</h5>
                <b-button
                    variant="primary"
                    v-if="
                        !details.fm_approval &&
                        userRole == 'Facility Management'
                    "
                    @click="approve(details.id, 'fm')"
                >
                    Approve
                </b-button>

                <p
                    v-if="
                        !details.fm_approval &&
                        userRole != 'Facility Management'
                    "
                >
                    Status : Pending approval
                </p>
                <p v-if="details.fm_approval">
                    Approved By {{ details.fm_approval_by }}
                </p>
                <p>Date : {{ details.fm_approval }}</p>
            </div>
        </div>
    </div>
</template>
<script>
import unitMixin from "../../../mixins/unitMixin";
export default {
    mixins: [unitMixin],
    props: {
        details: Object,
        type: String,
        url: String,
    },

    mounted() {
        if (localStorage.cs_user_token) {
            this.userRole = localStorage.cs_user_role;
        }
    },
    data() {
        return {
            userRole: null,
        };
    },
    methods: {
        approve(id, dept) {
            this.homeAndCommercialApproval(id, dept, this.type, this.url).then(
                () => {
                    this.$emit("refreshContent");
                }
            );
        },
    },
};
</script>
