var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',[_vm._v("Engineering Approvals")]),_c('br'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-3"},[_c('h5',[_vm._v("Civil Approval")]),(
                    !_vm.details.civil_approval &&
                    _vm.userRole == 'Engineering Executive'
                )?_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.approve(_vm.details.id, 'engg_civil')}}},[_vm._v(" Approve ")]):_vm._e(),(
                    !_vm.details.civil_approval &&
                    _vm.userRole != 'Engineering Executive'
                )?_c('p',[_vm._v(" Status : Pending Approval ")]):_vm._e(),(_vm.details.civil_approval)?_c('p',[_vm._v(" Approved By : "+_vm._s(_vm.details.civil_approval_by)+" ")]):_vm._e(),_c('p',[_vm._v("Date : "+_vm._s(_vm.details.civil_approval))])],1),_c('div',{staticClass:"col-lg-3"},[_c('h5',[_vm._v("MEP Approval")]),(
                    !_vm.details.mep_approval &&
                    _vm.userRole == 'Engineering Executive'
                )?_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.approve(_vm.details.id, 'engg_mep')}}},[_vm._v(" Approve ")]):_vm._e(),(
                    !_vm.details.mep_approval &&
                    _vm.userRole != 'Engineering Executive'
                )?_c('p',[_vm._v(" Status : Pending Approval ")]):_vm._e(),(_vm.details.mep_approval)?_c('p',[_vm._v(" Approved By : "+_vm._s(_vm.details.mep_approval_by)+" ")]):_vm._e(),_c('p',[_vm._v("Date : "+_vm._s(_vm.details.mep_approval))])],1),_c('div',{staticClass:"col-lg-3"},[_c('h5',[_vm._v("Architecture Approval")]),(
                    !_vm.details.architecture_approval &&
                    _vm.userRole == 'Engineering Executive'
                )?_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.approve(_vm.details.id, 'engg_architecture')}}},[_vm._v(" Approve ")]):_vm._e(),(
                    !_vm.details.architecture_approval &&
                    _vm.userRole != 'Engineering Executive'
                )?_c('p',[_vm._v(" Status : Pending Approval ")]):_vm._e(),(_vm.details.architecture_approval)?_c('p',[_vm._v(" Approved By : "+_vm._s(_vm.details.architecture_approval_by)+" ")]):_vm._e(),_c('p',[_vm._v("Date : "+_vm._s(_vm.details.architecture_approval))])],1),_c('div',{staticClass:"col-lg-3"},[_c('h5',[_vm._v("Engineering Approval")]),(!_vm.details.engg_approval && _vm.userRole == 'Engineering')?_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.approve(_vm.details.id, 'engg')}}},[_vm._v(" Approve ")]):_vm._e(),(!_vm.details.engg_approval && _vm.userRole != 'Engineering')?_c('p',[_vm._v(" Status : Pending Approval ")]):_vm._e(),(_vm.details.engg_approval)?_c('p',[_vm._v("Approved")]):_vm._e(),_c('p',[_vm._v("Date : "+_vm._s(_vm.details.engg_approval))])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }